
import { Channel } from "@/interfaces/channel/channel.dto";
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: "ModalChannelList",
  props: {
    input: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    generateLink(channel: Channel.Dto) {
      return `${process.env.VUE_APP_URL}/owner/auth/${channel.owner?._id}?channel=${channel._id}`
    }
  }
});
